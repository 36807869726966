$white1: #FFFFFF;

$black1: #111111;
$black2: #111114;

$grey1: #555555;
$grey2: #9A9AA2;
$grey3: #DDDDDD;
$grey4: #F2F2F7;
$grey5: #F8F8FA;
$grey6: #E4E4EB;

$navy1: #1E205F;
$navy2: #090A34;
$navy3: #54558E;
$navy4: #8C8DBE;

$purple1: #090A34;
$purple2: #1E205F;
$purple3: #54558E;
$purple4: #8C8DBE;

$yellow1: #F0CD45;
$yellow2: #DCA44D;

:export {
  white1: $white1;

  black1: $black1;
  black2: $black2;

  grey1: $grey1;
  grey2: $grey2;
  grey3: $grey3;
  grey4: $grey4;
  grey5: $grey5;
  grey6: $grey6;

  navy1: $navy1;
  navy2: $navy2;
  navy3: $navy3;

  purple1: $purple1;
  purple2: $purple2;
  purple3: $purple3;
  purple4: $purple4;

  yellow1: $yellow1;
  yellow2: $yellow2;
}