@import 'style/color.scss';

// ==============================|| 테두리 ||============================== //

.border-l-1 {
  border-left: 1px solid ;
}

.border-r-1 {
  border-right: 1px solid ;
}

.border-t-1 {
  border-top: 1px solid ;
}

.border-b-1 {
  border-bottom: 1px solid ;
}

.border-x-1 {
  border-left: 1px solid ;
  border-right: 1px solid ;
}

.border-y-1 {
  border-top: 1px solid ;
  border-bottom: 1px solid;
}

.border-grey3 {
  border-color: $grey3;
}

.border-grey4 {
  border-color: $grey4;
}

.border-black1 {
  border-color: $black1;
}

.border-black2 {
  border-color: $black2;
}





// ==============================|| MainContent ||============================== //

.SubIndexItem-Inactive {
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  padding: 8px 0;
  margin: 0 20px;
  color: $grey2;
  border-bottom: 4px solid transparent;
}
.SubIndexItem-Active {
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  padding: 8px 0;
  margin: 0 20px;
  color: $black1;
  border-bottom: 4px solid $black1;
}

.M-SubIndexItem-Inactive {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 8px 0;
  margin: 0 20px;
  color: $grey2;
  border-bottom: 4px solid transparent;
}

.M-SubIndexItem-Active {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 8px 0;
  margin: 0 20px;
  color: $black1;
  border-bottom: 4px solid $black1;
}
