@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

@import './_common.scss';
@import './fancast/_legacy.scss';

html {
  height: 100vh;
}

@media (min-width: 375px) {
  html {
    font-size: 62.5%;
  }
}

@media (max-width: 374px) {
  html {
    font-size: 50%;
  }
}

// ==============================|| Mui StyleOverrides ||============================== //

.MuiContainer-root {
  padding-left: 0;
  padding-right: 0;
}

body, button {
  margin: 0;
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}