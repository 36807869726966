
.Fancast-Legacy {

  div {
    line-height: 150%;
    font-size: 1.7rem;
    word-break: break-all;
  }

  table {
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    margin-top: 20px;
  }
  table tr td,
  table tr th {
    padding: 10px;
    text-align: left;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    vertical-align: top;
  }
}